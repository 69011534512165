import { useMemo } from 'react';

import { useAppRouter } from './useAppRouter';
import { useProductsQuery } from './useProductsQuery';

export const useProducts = () => {
  const { data } = useProductsQuery();
  const { funnelName, query } = useAppRouter();
  const products = useMemo(() => data?.products || [], [data]);

  const findProductById = (productId: string) => {
    return products.find(({ id }) => id === productId);
  };

  const getMainProducts = () => {
    if (funnelName === 'pcos') {
      return {
        1: {
          main: findProductById('12'),
          sub: findProductById('9'),
        },
        3: {
          main: findProductById('11'),
          sub: findProductById('8'),
        },
        6: {
          main: findProductById('10'),
          sub: findProductById('7'),
        },
      };
    }

    if (query.coupon === 'CALM10') {
      return {
        1: {
          main: findProductById('25'),
          sub: findProductById('26'),
        },
        3: {
          main: findProductById('24'),
          sub: findProductById('21'),
        },
        6: {
          main: findProductById('23'),
          sub: findProductById('20'),
        },
      };
    }

    return {
      1: {
        main: findProductById('18'),
        sub: findProductById('19'),
      },
      3: {
        main: findProductById('30'),
        sub: findProductById('28'),
      },
      6: {
        main: findProductById('29'),
        sub: findProductById('27'),
      },
    };
  };

  return {
    products,
    findProductById,
    mainProducts: getMainProducts(),
  };
};
