import { Select, ChakraStylesConfig, ReactSelectBaseProps, GroupBase } from 'chakra-react-select';
import { Loader } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useController } from 'react-hook-form';

import { BaseProps, FormControl } from '../form-control';

type Option = { label: string; value: string; placeId?: string };

type SelectControlProps = {
  isLoading?: boolean;
  inputProps?: Partial<ReactSelectBaseProps<Option, false, GroupBase<Option>>> & {
    menuIsOpen?: boolean;
    onOptionSelect?: (option: Option) => void;
    noOptionsMessage?: () => JSX.Element | null;
  };
  options: Option[];
  disabled?: boolean;
} & BaseProps;

export const SelectControl = ({
  inputProps,
  name,
  control,
  options,
  disabled,
  isLoading,
  ...rest
}: SelectControlProps) => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const {
    field,
    formState: { isSubmitting },
  } = useController({
    name,
    control,
  });
  const [value, setValue] = useState<Option | undefined>(undefined);

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (field.value) {
      setValue({ label: field?.value, value: field?.value });
    }
  }, [field]);

  // prevent SSR Hydration missamtch
  if (!isClient) {
    return null;
  }

  const handleChange = (data: unknown) => {
    const option = data as Option;

    setValue(option);
    field.onChange(option.value);

    if (inputProps?.onOptionSelect) {
      inputProps.onOptionSelect(option);
    }
  };

  const handleFocus = () => {
    setInputValue(value?.label);
  };

  const handleInputChange = (inpValue: string) => {
    setInputValue(inpValue);

    if (!inpValue && inputValue?.length === 1) {
      setValue(undefined);
    }
  };

  return (
    <FormControl name={name} control={control} {...rest}>
      <Select
        key={value?.label}
        {...field}
        {...inputProps}
        chakraStyles={styles}
        onChange={handleChange}
        value={value}
        options={options}
        isDisabled={isSubmitting || disabled}
        onInputChange={handleInputChange}
        onFocus={handleFocus}
        inputValue={inputValue}
        components={
          isLoading ? { DropdownIndicator: () => <Loader size={16} className='animate-spin mr-3' /> } : undefined
        }
      />
    </FormControl>
  );
};

const styles: ChakraStylesConfig<Option, false, GroupBase<Option>> = {
  menu: (provided) => ({
    ...provided,
    border: '1.5px solid var(--chakra-colors-black)',
    borderRadius: 'var(--chakra-radii-md)',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'gray.200' : 'white',
    color: 'var(--chakra-colors-black)',
    fontWeight: state.isSelected ? 'bold' : 'normal',
  }),
};
